import {
    Image,
    Divider,
    Input,
    List,
    Select,
    Spin,
    Tag,
    Drawer,
    Col,
    Row,
    Modal,
    Descriptions,
    Switch,
} from 'antd';
import { Footer, Header } from 'antd/lib/layout/layout';
import React, { useEffect, useState } from 'react';
import './App.css';
import { AimOutlined, BankOutlined, CoffeeOutlined } from '@ant-design/icons';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import { INSTITUTE_TYPES, REGIONS, SERVICES_COLORS } from './constants';
import { CulturalSite, getAllCulturalSites, getCulturalSite } from './services';

function serviceTagRender({ value, closable, onClose }: any) {
    return (
        <Tag
            closable={closable}
            onClose={onClose}
            color={SERVICES_COLORS[value as keyof typeof SERVICES_COLORS]}
        >
            {value}
        </Tag>
    );
}
const App: React.FC = () => {
    const [culturalSites, setCulturalSites] = useState<CulturalSite[]>([]);
    const [filteredCulturalSites, setFilteredCulturalSites] = useState<CulturalSite[]>([]);
    const [searchRegions, setSearchRegions] = useState<string[]>([]);
    const [searchTypes, setSearchTypes] = useState<string[]>([]);
    const [searchServices, setSearchServices] = useState<string[]>([]);
    // const [autoCompleteOptions, setAutoCompleteOptions] = useState<{ value: string }[]>([]);
    const [isLoading, setLoading] = useState(false);
    const [isFetching, setFetching] = useState(false);
    const [outlinedItem, setOutlinedItem] = useState<CulturalSite | undefined>();
    const [isViewing, setViewing] = useState(false);
    const [showMap, setShowMap] = useState(false);
    const [mapRange, setMapRange] = useState([0, 10]);

    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            setFilteredCulturalSites(
                culturalSites.filter(
                    (cs) =>
                        // type search
                        (searchTypes.length === 0 ||
                            searchTypes.some((v) => v.includes(cs.type) || cs.type.includes(v))) &&
                        // region search
                        (searchRegions.length === 0 ||
                            searchRegions.some(
                                (v) => v.includes(cs.region) || cs.region.includes(v),
                            )) &&
                        // service search
                        (searchServices.length === 0 ||
                            searchServices.every((ss: any) => cs.services?.includes(ss))),
                ),
            );
            setLoading(false);
        }, 200);
    }, [searchTypes, searchRegions, searchServices, culturalSites]);

    useEffect(() => {
        setFetching(true);
        getAllCulturalSites().then((data) => {
            setCulturalSites(data);
            // setAutoCompleteOptions(data.map((ci) => ({ value: ci.title })));
            setFilteredCulturalSites(data);
            setFetching(false);
        });
    }, []);

    const onSearch = (ev: any) => {
        const searchVal = ev.target.value;
        setLoading(true);
        setTimeout(() => {
            setFilteredCulturalSites(
                culturalSites.filter(
                    (cs) =>
                        // type search
                        (searchTypes.length === 0 ||
                            searchTypes.some((v) => v.includes(cs.type) || cs.type.includes(v))) &&
                        // region search
                        (searchRegions.length === 0 ||
                            searchRegions.some(
                                (v) => v.includes(cs.region) || cs.region.includes(v),
                            )) &&
                        // service search
                        (searchServices.length === 0 ||
                            searchServices.every((ss: any) => cs.services?.includes(ss))) &&
                        // name search
                        cs.title.toLowerCase().includes(searchVal.toLowerCase()),
                ),
            );
            setLoading(false);
        }, 200);
    };

    return (
        <Spin tip="Caricamento in corso..." spinning={isFetching} size="large">
            <div className="App">
                <Header style={{ width: '100%' }}>
                    <h2 className="title">Luoghi della Cultura in Italia</h2>
                </Header>
                <div className="wrapper">
                    <div className="search">
                        {/* <AutoComplete
                        style={{ width: '100%' }}
                        options={autoCompleteOptions}
                        onSelect={onSelect}
                        onSearch={onSearch}
                    > */}
                        <Input.Search
                            size="large"
                            placeholder="Cerca per denominazione..."
                            enterButton
                            allowClear
                            onChange={onSearch}
                            className="search-input"
                            style={{ marginBottom: 10 }}
                        />
                        {/* </AutoComplete> */}
                        <div className="search-filters">
                            <div className="filter-control">
                                <BankOutlined className="prefixIcon" />
                                <Select
                                    mode="tags"
                                    style={{ width: '100%' }}
                                    allowClear
                                    placeholder="Tipologia"
                                    onChange={(vals: any) => setSearchTypes(vals)}
                                >
                                    {INSTITUTE_TYPES.map((type) => (
                                        <Select.Option value={type} key={type}>
                                            {type}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </div>
                            <Divider type="vertical" />
                            <div className="filter-control">
                                <AimOutlined className="prefixIcon" />
                                <Select
                                    mode="tags"
                                    style={{ width: '100%' }}
                                    allowClear
                                    onChange={(vals: any) => setSearchRegions(vals)}
                                    placeholder="Regione"
                                >
                                    {REGIONS.map((region) => (
                                        <Select.Option value={region} key={region}>
                                            {region}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </div>

                            <Divider type="vertical" />
                            <div className="filter-control">
                                <CoffeeOutlined className="prefixIcon" />
                                <Select
                                    mode="tags"
                                    style={{ width: '100%' }}
                                    // style={{ width: '33%' }}
                                    allowClear
                                    onChange={(vals: any) => setSearchServices(vals)}
                                    placeholder="Servizi"
                                    tagRender={serviceTagRender}
                                >
                                    {Object.keys(SERVICES_COLORS).map((service) => (
                                        <Select.Option value={service} key={service}>
                                            {service}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </div>

                            <Divider type="vertical" />
                            <div>
                                Mappa
                                <Switch
                                    style={{ marginLeft: 7 }}
                                    onChange={(ch) => setShowMap(ch)}
                                    checked={showMap}
                                />
                            </div>
                        </div>
                    </div>
                    <Divider />
                    <Spin spinning={isLoading}>
                        {!isLoading && showMap && (
                            <MapContainer
                                center={[
                                    Number(filteredCulturalSites[0]?.latitude || 45.43707),
                                    Number(filteredCulturalSites[0]?.longitude || 12.342447),
                                ]}
                                zoom={5}
                                style={{
                                    zIndex: 0,
                                    height: 450,
                                    boxShadow: '0 0 10px grey',
                                    borderRadius: 7,
                                    marginBottom: 20,
                                    border: '1px solid grey',
                                }}
                                // scrollWheelZoom={false}
                            >
                                <TileLayer
                                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                />
                                {filteredCulturalSites.slice(mapRange[0], mapRange[1]).map(
                                    (cs) =>
                                        cs?.latitude &&
                                        cs?.longitude && (
                                            <Marker
                                                key={cs.id}
                                                position={[
                                                    Number(cs.latitude),
                                                    Number(cs.longitude),
                                                ]}
                                            >
                                                <Popup>{cs.title}</Popup>
                                            </Marker>
                                        ),
                                )}
                            </MapContainer>
                        )}
                        <List<CulturalSite>
                            bordered
                            className="search-list"
                            itemLayout="vertical"
                            split
                            size="small"
                            pagination={{
                                onChange: (page, pageSize) => {
                                    const pg = pageSize || 10;
                                    // console.log([page, pageSize]);
                                    const first = pg * (page - 1);
                                    setMapRange([first, first + pg]);
                                },
                                // pageSize: 10,
                                position: 'both',
                                // eslint-disable-next-line react/display-name
                                showTotal: (total, range) => (
                                    <div>
                                        {range[0]}-{range[1]} / {total}
                                    </div>
                                ),
                                style: { textAlign: 'center', marginBottom: 20 },
                            }}
                            dataSource={filteredCulturalSites}
                            renderItem={(item) => (
                                <List.Item
                                    key={item.id}
                                    // style={{ marginLeft: -16 }}
                                    onClick={async () => {
                                        setLoading(true);
                                        const fullItem = await getCulturalSite(item.id);
                                        setLoading(false);
                                        if (fullItem) {
                                            setOutlinedItem(fullItem);
                                            setViewing(true);
                                        }
                                    }}
                                >
                                    <List.Item.Meta
                                        title={`${item.id} - ${item.title}`}
                                        style={{ marginBottom: 0 }}
                                        // description={item.type}
                                        avatar={
                                            <div className="search-thumbnail-default">
                                                {item.image_url?.match(/.png|.jpg|.gif/) && (
                                                    <Image
                                                        alt={item.title}
                                                        className="search-thumbnail"
                                                        width={200}
                                                        height={150}
                                                        src={item.image_url}
                                                    />
                                                )}
                                            </div>
                                        }
                                        description={
                                            <>
                                                {/* <div>ID: {item.id}</div> */}
                                                <div>Tipo: {item.type}</div>
                                                <div style={{ color: '#383838' }}>
                                                    <div>
                                                        Indirizzo: {item.fulladdress} {item.zipcode}
                                                        {/* {item.address} - {item.zipcode}{' '}
                                                {item.city} */}
                                                    </div>
                                                    <div>Regione: {item.region}</div>
                                                    <div style={{ marginTop: 10 }}>
                                                        {item.services?.map((service) => (
                                                            <Tag
                                                                key={service}
                                                                color={SERVICES_COLORS[service]}
                                                            >
                                                                {service}
                                                            </Tag>
                                                        ))}
                                                    </div>
                                                </div>
                                            </>
                                        }
                                    />
                                    {/* {extractTextFromHtml(item.description, 100)} */}
                                </List.Item>
                            )}
                        />
                    </Spin>
                    <Modal
                        title={outlinedItem?.title}
                        onCancel={() => {
                            setOutlinedItem(undefined);
                            setViewing(false);
                        }}
                        visible={isViewing}
                        className="search-detail"
                        footer={null}
                        destroyOnClose
                    >
                        <Spin spinning={isLoading}>
                            {outlinedItem?.latitude && outlinedItem?.longitude && (
                                <MapContainer
                                    center={[
                                        Number(outlinedItem.latitude),
                                        Number(outlinedItem.longitude),
                                    ]}
                                    zoom={12}
                                    style={{
                                        height: 300,
                                        boxShadow: '0 0 10px grey',
                                        borderRadius: 7,
                                        marginBottom: 20,
                                        border: '1px solid grey',
                                    }}
                                >
                                    <TileLayer
                                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                    />
                                    <Marker
                                        position={[
                                            Number(outlinedItem.latitude),
                                            Number(outlinedItem.longitude),
                                        ]}
                                    >
                                        <Popup>{outlinedItem.title}</Popup>
                                    </Marker>
                                </MapContainer>
                            )}
                            <Descriptions
                                // title={outlinedItem?.title}
                                bordered
                                layout="vertical"
                                size="small"
                                column={1}
                            >
                                {outlinedItem?.image_url?.match(/.png|.jpg|.gif/) && (
                                    <Descriptions.Item label="Copertina">
                                        <Image
                                            alt={outlinedItem?.title}
                                            className="search-thumbnail"
                                            width={300}
                                            src={outlinedItem?.image_url}
                                        />
                                    </Descriptions.Item>
                                )}
                                <Descriptions.Item label="Tipo">
                                    {outlinedItem?.type}
                                </Descriptions.Item>
                                <Descriptions.Item label="Indirizzo">
                                    {outlinedItem?.fulladdress} {outlinedItem?.zipcode}
                                </Descriptions.Item>
                                <Descriptions.Item label="Regione">
                                    {outlinedItem?.region}
                                </Descriptions.Item>
                                <Descriptions.Item label="Servizi">
                                    {outlinedItem?.services === null
                                        ? '-'
                                        : outlinedItem?.services?.map((service) => (
                                              <Tag key={service} color={SERVICES_COLORS[service]}>
                                                  {service}
                                              </Tag>
                                          ))}
                                </Descriptions.Item>
                            </Descriptions>
                            <Divider>Descrizione</Divider>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: outlinedItem?.description || '',
                                }}
                            />
                            {outlinedItem?.openings && outlinedItem?.openings.length > 0 && (
                                <>
                                    <Divider>Apertura / Biglietti</Divider>
                                    <Descriptions
                                        // title={outlinedItem?.title}
                                        bordered
                                        layout="vertical"
                                        // layout="horizontal"
                                        size="small"
                                        column={1}
                                    >
                                        {outlinedItem?.openings.map((opening) => (
                                            <Descriptions.Item
                                                key={opening.label}
                                                label={opening.label}
                                            >
                                                {opening.description}
                                            </Descriptions.Item>
                                        ))}
                                    </Descriptions>
                                </>
                            )}
                            <Divider>Contatti</Divider>
                            <Descriptions
                                // title={outlinedItem?.title}
                                bordered
                                layout="vertical"
                                // layout="horizontal"
                                size="small"
                                column={1}
                            >
                                <Descriptions.Item label="Telefono">
                                    <ul>
                                        {outlinedItem?.phones?.map((phone) => (
                                            <li key={phone}>
                                                <a
                                                    target="_blank"
                                                    href={`tel://${phone}`}
                                                    rel="noreferrer"
                                                >
                                                    {phone}
                                                </a>
                                            </li>
                                        ))}
                                    </ul>
                                </Descriptions.Item>
                                <Descriptions.Item label="Email">
                                    <ul>
                                        {outlinedItem?.emails?.map((email) => (
                                            <li key={email}>
                                                <a target="_blank" href={email} rel="noreferrer">
                                                    {email}
                                                </a>
                                            </li>
                                        ))}
                                    </ul>
                                </Descriptions.Item>
                                {outlinedItem?.websites && (
                                    <Descriptions.Item label="Sito Web">
                                        <ul>
                                            {outlinedItem?.websites?.map(
                                                (website) =>
                                                    website !== '' && (
                                                        <li key={website}>
                                                            <a
                                                                target="_blank"
                                                                href={
                                                                    website.includes('http')
                                                                        ? website
                                                                        : `http://${website}`
                                                                }
                                                                rel="noreferrer"
                                                            >
                                                                {website}
                                                            </a>
                                                        </li>
                                                    ),
                                            )}
                                        </ul>
                                    </Descriptions.Item>
                                )}
                            </Descriptions>
                        </Spin>
                    </Modal>

                    <Footer className="footer">&copy; LiveMuseum.it</Footer>
                </div>
            </div>
        </Spin>
    );
};

export default App;
