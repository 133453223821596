import { message } from 'antd';
import mock from './mock.json';
import mockSingle from './mock-single.json';
import { API_URL, SERVICES_COLORS } from '../constants';

export interface CulturalSite {
    id: string;
    title: string;
    description?: string;
    fulladdress: string | null;
    address: string | null;
    province: string;
    city: string;
    region: string;
    zipcode: string | null;
    latitude: string | null;
    longitude: string | null;
    type: string;
    image_url: string | null;
    services: (keyof typeof SERVICES_COLORS)[] | null;
    emails?: string[];
    phones?: string[];
    websites?: string[];
    openings: { label: string; description: string }[];
}

export async function getAllCulturalSites(): Promise<CulturalSite[]> {
    // return mock as any;
    return (await fetch(`${API_URL}/culturalSites`, { method: 'POST' })).json();
}

export async function getCulturalSite(id: string | number): Promise<CulturalSite> {
    // return mockSingle as any;
    return fetch(`${API_URL}/culturalSites/${id}`, { method: 'POST' })
        .then((data) => data.json())
        .catch((err) => {
            message.error('Errore server. Contattare assistenza.');
            console.error(err);
        });
}

export default {};
